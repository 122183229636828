import { defineStore } from 'pinia'
import request from "@/assets/js/request";
import Router from "@/router";
import { useRoute, useRouter } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import eventBus from "@/assets/js/eventBus";


// 第一个参数是id，id必填，且需要保证值唯一
export const userStore = defineStore('user', {
  state: (): userStore => {
    return {
      userInfo: {},
      uid: 0,
      token: '',
      historyList: [],//对话列表
      msgList: [],//对话详情
      endItem: {content: ''},
      url: 'https://chat.yookeer.com',
      uploadUrl:  'https://chat.yookeer.com/api/common/upload',
    }
  },
  /*getters: {
    newName: state => state.userInfo.name
  },*/
  actions: {
    //选择对话
    selectChat(item: historyItem) {
      ElMessageBox.confirm('确认要切换到该对话吗?','提示',{type: "info"})
        .then(() => {
          request<msgItem[]>("POST","/user/getlist",{
            ppid: item.ppid
          },'加载中')
            .then(res =>{
              if(res.code==1) {
                this.msgList = res.data.map(item => {
                  item.end = 1
                  return item
                })
                localStorage.setItem('msgList',JSON.stringify(this.msgList))
                localStorage.setItem('ppid',String(item.ppid))
                eventBus.emit('scrollBottom',1234)
                Router.push('/')
              }else {
                ElMessageBox.alert(res.msg,'提示',{type: "error"})
              }
            })
        })
    },
    //创建新对话
    createNewChat() {
      ElMessageBox.confirm('确认要创建新聊天吗？','提示',{type: 'info'})
        .then(con => {
          console.log(con);
          eventBus.emit('createChat','哈哈')
          this.msgList = []
          localStorage.removeItem('ppid')
          localStorage.removeItem('msgList')
          Router.push('/')
        })
    },
    //创建流对话 type 1=对话 2=应用
    initEventSource(type=1,id: string,endFn?: VoidFunction,scrollFn?: VoidFunction) {
      const url = this.url+"/api/index/apis"+`?id=${id}&uid=${this.uid}`;
      const eventSource = new EventSource(url);

      eventSource.addEventListener("open", (event) => {
        console.log("连接已建立", JSON.stringify(event));
      });
      eventSource.addEventListener("message", (event) => {
        console.log('正常获取信息eventeventeventevent',event)
        try {
          const result = JSON.parse(event.data);
          if(result.id && this.endItem.end!=1){
            if(type===1) {
              this.endItem.end = result.end
              this.endItem.content = this.endItem.content+result.massage
              localStorage.setItem('msgList',JSON.stringify(this.msgList))
            }else {
              eventBus.emit('getAppContent',result)
            }
            if(scrollFn) scrollFn()
          }
        } catch (error) {
          console.log(error,'发生错误，请重试22-----------');
        }
      });
      eventSource.addEventListener("error", (event) => {
        if(endFn) endFn()
        console.error('发生错误，报错',event);
        this.endItem.content = '提问和回答的内容超过了字数上限，请重新输入!'
        if(scrollFn) scrollFn()
        return ElMessage.error('提问和回答的内容超过了字数上限，请重新输入!')
        console.error("发生错误：", JSON.stringify(event));
      });
      eventSource.addEventListener("close", (event) => {
        console.log("连接已关闭", JSON.stringify(event));
        eventSource.close();
        if(endFn) endFn()
        console.log((new Date().getTime()), 'answer end');
      });
    },
    //获取对话列表
    getList() {
      if(localStorage.getItem('yookeerId')) {
        request<historyItem[]>("POST","/user/getlishi",{})
          .then(res =>{
            this.historyList = res.data
            console.log(res.data);
          })
      }
    },
    //清空所有对话
    delAll() {
      ElMessageBox.confirm('确认要清空所有对话吗?','提示',{type: "warning"})
        .then(con => {
          console.log(con);
          request("POST","/user/dellishis",{},'删除中')
            .then(res =>{
              if(res.code==1) {
                ElMessageBox.alert('删除成功','提示',{type: "success"})
                this.getList()
                localStorage.removeItem('ppid')
                localStorage.removeItem('msgList')
                this.msgList = []
              }else {
                ElMessageBox.alert(res.msg,'提示',{type: "error"})
              }
            })
        })
    },
    //删除对话列表
    delHistory(item: historyItem) {
      ElMessageBox.confirm('确认要删除该对话?','提示',{type: "warning"})
        .then(con => {
          console.log(con);
          request("POST","/user/dellishi",{ppid: item.ppid},'删除中')
            .then(res =>{
              if(res.code==1) {
                ElMessageBox.alert('删除成功','提示',{type: "success"})
                this.getList()
                this.msgList = []
                if(String(item.ppid) === String(localStorage.getItem('ppid'))) {
                  localStorage.removeItem('ppid')
                  localStorage.removeItem('msgList')
                }
              }else {
                ElMessageBox.alert(res.msg,'提示',{type: "error"})
              }
            })
        })
    },
    //获取用户信息
    async getUserInfo() {
      if(localStorage.getItem('yookeerId')) {
        this.uid = Number(localStorage.getItem('yookeerId')!)
        const res = await request<UserInfo>("POST","/user/index",{})
        if(res.code===1) {
          this.userInfo = res.data
          return res.data
        }else {
          return null
        }
      }else {
        const Router = useRouter()
        const Route = useRoute()
        Router.isReady().then(() => {
          console.log(Route.query);
          if(Route.query.uid) {
            localStorage.setItem('yookeerId',Route.query.uid+'')
            this.getUserInfo()
            this.getList()
          }else {
            window.location.href = 'https://chat.yookeer.com/third/connect/wechat'
          }
        })
      }
    },
    //获取缓存
    getStorage() {
      const uInfo = localStorage.getItem('yookeerInfo')?JSON.parse(localStorage.getItem('yookeerInfo')!):null
      if(uInfo) {
        this.userInfo = uInfo
      }
      this.getUserInfo()
      this.getList()
    },
  }
})
