import { ElLoading } from "element-plus";

const baseUrl = "https://chat.yookeer.com/api";
interface Data {
  [key: string]: any;
}
interface Res<T> {
  code: number;
  msg: string;
  data: T;
}
type Methods = "POST" | "GET";
function request<T>(method: Methods, url: string, data: Data, text?: string,noMember?: boolean) :Promise<Res<T>> {
  let loading:any = null; //加载提示实例
  if(localStorage.getItem('yookeerId')) data.uid = localStorage.getItem('yookeerId')
  return new Promise((resolve, reject) => {
    if(text) {
      loading = ElLoading.service({
        fullscreen: true,
        text: text
      })
    }
    //1创建异步对象
    const xhr = new XMLHttpRequest();
    //4创建监听 接收响应
    xhr.onreadystatechange = () => {
      try {
        if(!xhr.responseText) return
        if(text && loading) {
          loading.close();
        }
        const result: Res<T> = JSON.parse(xhr.responseText);
        if ((xhr.readyState == 4 && xhr.status == 200) || xhr.status == 401) {
          resolve(result); //返回出去
        } else {
          // reject(result);
        }
      }catch (e) {
        console.error(e)
        // reject(e);
      }
    };
    //2创建请求 打开连接
    url = baseUrl + url;
    
    xhr.open(method, url, true);
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    // xhr.setRequestHeader("token", token);

    let paramsData = "";
    if (method == "POST") {
      paramsData = ""; //创建参数
      if (Object.keys(data) && Object.keys(data).length > 0) {
        Object.keys(data).forEach(function (key) {
          //循环参数
          paramsData += key + "=" + encodeURIComponent(data[key]) + "&";
        });
        paramsData = paramsData.substring(0, paramsData.length - 1); //去除最后一位
      }
    }
    //3发送请求
    xhr.send(paramsData);
  });
}
export default request;
