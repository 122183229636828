const setRem = (wid: number) => {
  const reScreenSize = (wid?:number) => {
    // 当设备宽度小于1400px时，不在改变rem的值
    if (wid && wid <= 1400) return;
    const w = document.documentElement.clientWidth; // 获取设备的宽度
    const n =
      10 * (w / 1920) > 40
        ? 40 + 'px'
        : 10 * (w / 1920) >= 7.4
          ? 10 * (w / 1920) + 'px'
          : 7.4 + 'px';
    document.documentElement.style.fontSize = n;
    console.log(n);
  };
  reScreenSize(wid);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.addEventListener('load', reScreenSize);
  window.addEventListener('resize', () => reScreenSize(document.documentElement.clientWidth));


  /*const setRemRoot = (regexp:RegExp,regexp2:RegExp,selectors:string,className:string) => {
    const elems = document.querySelectorAll(selectors);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    elems.forEach((elem:HTMLElement) => {
      const matches = elem.className.match(regexp);
      if (matches) {
        const result = matches[1].match(regexp2);
        if(result) {
          console.log(result);
          const num:string = matches ? String(result[1]) : '0';
          elem.style.setProperty(className, num );
        }
      }
    });
  }
  setRemRoot(/([^"]*\bwr[\d.]+[^"]*)/,/wr([\d.]+)/,'[class*=wr]','--w')
  setRemRoot(/([^"]*\bhr[\d.]+[^"]*)/,/hr([\d.]+)/,'[class*=hr]','--h')
  setRemRoot(/([^"]*\bfr[\d.]+[^"]*)/,/fr([\d.]+)/,'[class*=fr]','--fr')*/
};
export default setRem;