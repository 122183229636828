import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import { currentPath } from "@/store/pinia/path";
import chat from "@/views/Home/chat.vue";
import share from "@/views/User/share.vue";
import aboutUs from "@/views/User/aboutUs.vue";
import me from "@/views/User/me.vue";
import vip from "@/views/User/vip.vue";
import apps from "@/views/Home/apps.vue";
import appDetail from "@/views/Home/appDetail.vue";
import translate from "@/views/Home/translate.vue";
import translateHistory from "@/views/Home/translateHistory.vue";
import textOcr from "@/views/Home/textOcr.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {title: '首页'},
    children: [{
      path: "",
      name: "chat",
      component: chat,
      meta: {title: '首页2'},
    },{
      path: "/apps",
      name: "apps",
      component: apps,
      meta: {title: '应用中心',keepAlive: true}
    },{
      path: "/appDetail",
      name: "appDetail",
      component: appDetail,
      meta: {title: '详情'}
    },{
      path: "/translate",
      name: "translate",
      component: translate,
      meta: {title: '详情'}
    },{
      path: "/textOcr",
      name: "textOcr",
      component: textOcr,
      meta: {title: '详情'}
    },{
      path: "/translateHistory",
      name: "translateHistory",
      component: translateHistory,
      meta: {title: '翻译记录'}
    },{
      path: "/me",
      name: "me",
      component: me,
      meta: {title: '用户中心'}
    },{
      path: "/share",
      name: "share",
      component: share,
      meta: {title: '邀请好友'}
    },{
      path: "/aboutUs",
      name: "aboutUs",
      component: aboutUs,
      meta: {title: '关于我们'}
    },{
      path: "/vip",
      name: "vip",
      component: vip,
      meta: {title: '会员中心'}
    },]
  },
];
const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
router.beforeEach((to,from) => {
  const path = currentPath();
  //修改路由
  path.updatePath(to.fullPath, to.matched,to.meta)
  //修改标题
  to.meta.title ? document.title = to.meta.title as string : ''
  //滚动到页面顶部
  window.scrollTo({
    top: 0
  })
})


export default router;
