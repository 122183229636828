import {ElMessageBox} from "element-plus";
import Router from '@/router'

const tools = {
    //去除html标签
    stripHTMLTags(html: string) {
        return html.replace(/<\/?[^>]+(>|$)/g, "");
    },
    //判断是否登录
    isLogin() {
        if (!localStorage.getItem('yookeerId')) {
            ElMessageBox.confirm('请登录后操作', '提示', {type: 'warning'})
            return true
        } else {
            return false
        }
    },
    onshow(fn:VoidFunction) {
        document.addEventListener('visibilitychange', function() {
            if (document.visibilityState === 'hidden') {

            } else {
                fn();
            }
        });
    },
    //手机号脱敏
    hidePhone(tel:string) {
        const reg = /^(\d{3})\d{4}(\d{4})$/;
        if(tel) {
            return tel.replace(reg, "$1****$2");
        }else {
            return ''
        }
    },
    tmCheckImage: function(fileName: string) {
        return /(gif|jpg|jpeg|png|GIF|JPG|PNG)$/ig.test(fileName);
    },

}
export default tools;