import { defineStore } from 'pinia'
import router from "@/router";


// 第一个参数是id，id必填，且需要保证值唯一
export const currentPath = defineStore('path', {
  state: (): {
    path: string
    matched: Array<{path: string,name?: string}>,
    meta: Obj
  } => {
    return {
      path: '',//当前路由
      matched: [{path: '/'}],
      meta: {}
    }
  },
  /*getters: {
    newName: state => state.userInfo.name
  },*/
  actions: {
    updatePath(path: string,matched: Array<{path: string}>,meta: Obj) {
      this.path = path
      this.matched = matched
      this.meta = meta
      console.log(this.path)
    },
  }
})
