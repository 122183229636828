import { createApp } from "vue";
import App from "./App.vue";
const app = createApp(App);


import router from "./router";
import store from "./store"; //pinia或者vuex
//公共方法 样式
import "./assets/css/common.scss"; //公共sass
import "./assets/css/eightysix.css"; //公共css
import "./assets/css/reset.css"; //公共css
import "./assets/css/animation.css"; //color ui 动画库
//elemenui
import ElementPlus from "element-plus";
import "element-plus/theme-chalk/base.css";
import "element-plus/dist/index.css"; //elementui默认样式
import zhCn from 'element-plus/dist/locale/zh-cn.mjs';//中文
import * as ElementPlusIcons from "@element-plus/icons-vue";
for (const [key, component] of Object.entries(ElementPlusIcons)) {
  app.component(key, component);
};
//markdown编译器
import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import "./assets/css/theme/github.css";
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
// import '@kangc/v-md-editor/lib/theme/style/github.css';
// import '@kangc/v-md-editor/lib/theme/style/vuepress.css';
import hljs from 'highlight.js';
VMdPreview.use(githubTheme, {
  Hljs: hljs,
}); //代码高亮
import createCopyCodePlugin from '@kangc/v-md-editor/lib/plugins/copy-code/index';
import '@kangc/v-md-editor/lib/plugins/copy-code/copy-code.css';
VMdPreview.use(createCopyCodePlugin()); //代码复制
import createKatexPlugin from '@kangc/v-md-editor/lib/plugins/katex/cdn';
VMdPreview.use(createKatexPlugin()); //katex
import createLineNumbertPlugin from '@kangc/v-md-editor/lib/plugins/line-number/index'; //代码行
VMdPreview.use(createLineNumbertPlugin());

//rem动态
import setRem from '@/hooks/setRem' //设置rem
setRem(1920);

//v-viewer图片预览
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'

app.use(store).use(router).use(ElementPlus,{locale: zhCn}).use(VMdPreview).use(VueViewer,{
  defaultOptions: {zIndex: 9999}
}).mount("#app");
